<template>
  <v-container fluid>
    <v-data-iterator
      v-if="!(videos.length === 0 && viewOnly)"
      :items="videos"
      :sort-by="'unix'"
      :sort-desc="true"
      :items-per-page="4"
    >
      <template v-slot:default="props">
        <v-row dense>
          <v-col
            v-for="(item) in props.items"
            :key="item.id"
            cols="12" sm="6" md="4" xl="3">
            <v-card height="100%" class="flexcard">
              <v-card-text v-if="item.t && item.t.length > 1" class="text-center pb-0">
                <div class="title"><fit-text>{{item.t[0]}}</fit-text></div>
                <div>vs</div>
                <div class="title"><fit-text>{{item.t[1]}}</fit-text></div>
              </v-card-text>
              <v-card-title v-else>
                <fit-text>{{item.props.title}}</fit-text>
              </v-card-title>
              <v-card-text class="grow pt-0 caption">
                <div>{{item.props.organization}}</div>
                <div>{{item.props.tournament}} ({{item.props.dateTime | localDate}})</div>
                <div>{{item.props.division}}</div>
                <div>{{item.props.round}} | {{item.props.subround}} | {{item.props.match}}</div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="justify-space-between">
                <v-btn
                  text small
                  color="color3 color3Text--text"
                  @click.stop="watch(item)"
                >
                  watch replay<v-icon class="ml-2" small>fas fa-tv</v-icon>
                </v-btn>
                <v-btn
                  color="color3"
                  text x-small
                  :to="{name : item.poolId ? 'pool-sheet' : 'bracket-home', params: { tournamentId: item.tournamentId, divisionId: item.divisionId, dayId: item.props.roundId, poolId: item.poolId, bracketId: item.bracketId }}"
                >
                  Jump to the {{item.poolId ? 'Pool Sheet' : 'Bracket'}} <v-icon class="ml-3">fas fa-caret-right</v-icon>
                </v-btn>
              </v-card-actions>
              <v-btn color="error" x-small text @click.stop="selected = item" v-if="editCapabale">remove</v-btn>
            </v-card>
            <div>
            </div>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <div v-if="videos.length === 0 && viewOnly" class="text-center pa-3 pt-0">
      {{player.firstName}} doesn't have any match streams yet.
    </div>
    <vbl-confirm-dialog
      v-if="editCapabale"
      :ask="!!selected"
      :loading="deleteing"
      @deny="selected = null"
      @confirm="doDelete"
    ></vbl-confirm-dialog>
  </v-container>
</template>

<script>
import moment from 'moment'
import { api } from '@/classes/_URL'
import { mapGetters } from 'vuex'

export default {
  props: ['player', 'active', 'viewOnly'],
  data () {
    return {
      loading: false,
      loaded: false,
      deleteing: false,
      uploading: false,
      videos: [],
      selected: null
    }
  },
  computed: {
    ...mapGetters(['user']),
    editCapabale () {
      return this.user && this.user.vbl
    }
  },
  methods: {
    setVideos (data) {
      this.videos = data.map(m => {
        m.unix = moment(m.props.dateTime.replace('Z', '')).unix()
        m.t = m.props.title.split(' vs ')
        return m
      })
    },
    loadVideos () {
      if (this.loaded) return
      this.loading = true
      this.$VBL.player.streams.get(this.player.id)
        .then(r => {
          this.setVideos(r.data)
        })
        .catch(e => {
          console.log(e.response)
        })
        .finally(() => { this.loading = false })
    },
    watch (item) {
      this.loading = true
      this.$http.get(`${api}/livestream/watch/${item.id}`)
        .then(r => {
          window.open(r.data, '_blank')
        })
        .finally(() => {
          this.loading = false
        })
    },
    doDelete () {
      this.deleteing = true
      this.$VBL.player.streams.setShow(this.player.id, this.selected.id, false)
        .then(r => {
          this.setVideos(r.data)
          this.selected = false
        })
        .catch(e => {
          console.log(e.response)
        })
        .finally(() => { this.deleteing = false })
    }
  },
  watch: {
    active: 'loadVideos'
  },
  mounted () {
    if (this.active) {
      this.loadVideos()
    }
  }
}
</script>

<style scoped>

</style>
